@import url("https://fonts.googleapis.com/css?family=Gulzar:400");

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role="list"],
ol[role="list"] {
  list-style: none;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeLegibility;
  line-height: 1.5;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

html {
  font-size: 100%;
} /*16px*/

body {
  background: white;
  font-family: "Gulzar", serif;
  font-weight: 400;
  line-height: 1.75;
  color: #000000;
}

.body-container {
    display: grid;
    height: 100vh;
    grid-template-rows: auto 1fr auto;
}

header {
  width: 100%;
}

main {
  background: rgb(255, 255, 255);
  padding: 2rem 0;
  width: 100%;
}

footer {
  padding: 4rem 2rem 2rem;
  text-align: center;
  width: 100%;
}

.container {
  max-width: 1024px;
  margin: 0 auto;
  padding: 1rem;
}

.header-container {
  max-width: 1024px;
  margin: 0 auto;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
}

/*------------------------------------*\
    #TYPE
\*------------------------------------*/

h1,
h2,
h3,
h4,
h5 {
  margin: 3rem 0 1.38rem;
  font-family: "Gulzar", serif;
  font-weight: 400;
  line-height: 1.3;
}

h1,
.h1 {
  margin-top: 0;
  font-size: 3.052rem;
}

h2,
.h2 {
  font-size: 2.441rem;
}

h3,
.h3 {
  font-size: 1.953rem;
}

h4,
.h4 {
  font-size: 1.563rem;
}

h5,
.h5 {
  font-size: 1.25rem;
}

small,
.text_small {
  font-size: 0.8rem;
}

p {
  margin-bottom: 1rem;
}

a {
  color: black;
  text-decoration: none;
}

a:hover,
a:focus {
  color: black;
  text-decoration: underline;
}

a[href^="http"].external::after,
a[href^="https://"].external::after
{
  content: "";
  width: 11px;
  height: 11px;
  margin-left: 4px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z'/%3E%3Cpath fill-rule='evenodd' d='M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z'/%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
}

/*------------------------------------*\
    #Found
\*------------------------------------*/
/* layout styles */
.parent {
  display: grid;
  grid-gap: 3rem;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

.box {
  aspect-ratio: 6/4;
}
.box img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  aspect-ratio: auto 6/4;
}

.six-four {
  aspect-ratio: auto 6/4 !important;
}

.five-four {
  aspect-ratio: auto 5.4375/4.125 !important;
}

/* flipcard styles */
.flip {
  position: relative;
  background-color: rgba(0, 0, 0, 0.05);
}
.flip button {
  position: absolute;
  background: transparent;
  border: none;
  padding: 4px;
}
.flip > .front,
.flip > .back {
  display: block;
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition-duration: 0.5s;
  transition-property: transform, opacity;
  backface-visibility: hidden;
}
.flip > .front {
  transform: rotateY(0deg);
}
.flip > .back {
  position: absolute;
  opacity: 0;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  transform: rotateY(-180deg);
}
.flip.active > .front {
  transform: rotateY(180deg);
}
.flip.active > .back {
  opacity: 1;
  transform: rotateY(0deg);
}

#lightbox-img {
  max-height: 80vh;
  max-width: 90vw;
  object-fit: cover;
}

#lightbox {
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.65);
  display: grid;
  place-items: center;
  height: 100%;
}

.visually-hidden:not(:focus):not(:active) {
  clip: rect(0 0 0 0);
  clip-path: inset(100%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.collapse {
  margin-bottom: 1rem;
}

.collapse p {
  margin: 0
}
